import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Article from '../components/Article';
import RichText from '../components/RichText';
import { graphql } from 'gatsby';

export const pageQuery = graphql`
  {
    contentfulArticle(slug: { eq: "impressum" }) {
      name
      text {
        json
      }
    }
  }
`;

export default props => (
  <Layout>
    <SEO
      title={props.data.contentfulArticle.name}
      keywords={[`Rebberg Sulzberg`, `Impressum`]}
    />
    <Article title={props.data.contentfulArticle.name}>
      <RichText document={props.data.contentfulArticle.text.json} />
    </Article>
  </Layout>
);
